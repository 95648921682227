import LogRocket from 'logrocket';
import { isRunningInServer } from 'upx-tools/next';

export function initLogRocket() {
  if (isRunningInServer() || !process.env.NEXT_PUBLIC_LOGROCKET_APP_ID) {
    return;
  }

  LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID);

  // Identify user using Segment's Anonymous User ID
  window.analytics.ready(() => {
    LogRocket.identify((window.analytics.user() as any).anonymousId());
  });
}
