import type { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { initLogRocket } from 'upx-tools/logrocket';
import { initSegment } from 'upx-tools/segment';
import { initSentry } from 'upx-tools/sentry';
import '../styles/styles.css';

initSegment();
initLogRocket();
initSentry();

const queryClient = new QueryClient();

//@ts-ignore
export default function MyApp({ Component, pageProps, err }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <title>UPX Tools</title>
        <link rel="shortcut icon" href="favicon.svg" type="image/svg+xml" />
        <meta name="description" content="Check the visibility of your BGP routes globally." />
        <meta name="keywords" content="bgp, routes, upstream, visibility, upx, tools, asn" />
        <meta name="viewport" content="width=device-width initial-scale=1.0 user-scalable=no" />
      </Head>
      <Component {...pageProps} err={err} />
    </QueryClientProvider>
  );
}
