import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';
import LogRocket from 'logrocket';
import { isRunningInServer } from 'upx-tools/next';

export const initSentry = () => {
  if (!process.env.NEXT_PUBLIC_SENTRY_DSN) {
    return;
  }

  const integrations = [];

  if (isRunningInServer() && process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR) {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          //@ts-ignore
          frame.filename = frame.filename?.replace(process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR, 'app:///');
          frame.filename = frame.filename?.replace('.next', '_next');
          return frame;
        },
      }),
    );
  }

  Sentry.init({
    integrations,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  });

  if (!isRunningInServer()) {
    // Attach LogRocket session URL
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });

    // Identify user using Segment's Anonymous User ID
    window.analytics.ready(() => {
      Sentry.configureScope((scope) =>
        scope.setUser({
          id: (window.analytics.user() as any).anonymousId(),
        }),
      );
    });
  }
};
